import React from "react"

const InstagramIcon = ({ fillColor = "purple", className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="61"
      viewBox="0 0 60 61"
      className={`fill-${fillColor} ${className}`}
    >
      <path d="M59.807 17.862a21.69 21.69 0 00-1.396-7.371 15.332 15.332 0 00-8.773-8.758A22.042 22.042 0 0042.355.336C39.148.193 38.133.153 29.997.153c-8.137 0-9.18 0-12.361.183-2.489.051-4.95.524-7.28 1.397a15.352 15.352 0 00-5.313 3.455 15.322 15.322 0 00-3.46 5.303 21.769 21.769 0 00-1.396 7.268C.043 20.962 0 21.977 0 30.099c0 8.123 0 9.161.187 12.341.05 2.488.52 4.943 1.396 7.275a15.332 15.332 0 008.776 8.755 21.968 21.968 0 007.28 1.497c3.208.143 4.225.186 12.361.186 8.136 0 9.18 0 12.361-.186a21.99 21.99 0 007.284-1.394 15.378 15.378 0 008.772-8.758 21.875 21.875 0 001.396-7.275c.144-3.2.187-4.214.187-12.34-.007-8.123-.007-9.155-.193-12.338zm-29.83 27.599c-8.51 0-15.404-6.883-15.404-15.378 0-8.495 6.894-15.378 15.404-15.378 4.085 0 8.003 1.62 10.891 4.504a15.365 15.365 0 014.512 10.874c0 4.078-1.623 7.99-4.512 10.873a15.416 15.416 0 01-10.891 4.505zm16.016-27.739a3.595 3.595 0 01-3.32-2.213 3.575 3.575 0 01.001-2.744 3.584 3.584 0 013.317-2.213 3.596 3.596 0 013.317 2.213 3.585 3.585 0 01-3.315 4.957z"></path>
      <path d="M29.977 40.072c5.526 0 10.005-4.472 10.005-9.99 0-5.516-4.48-9.988-10.005-9.988-5.526 0-10.006 4.472-10.006 9.989s4.48 9.989 10.006 9.989z"></path>
    </svg>
  )
}

export default InstagramIcon
