import React from "react"

export const LanguageSwitch = ({ className = "" }) => (
  <svg
    width="82"
    height="83"
    viewBox="0 0 82 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`icon__languageSwitch ${className}`}
  >
    <path
      d="M68.3333 7.15076H31.8889V18.5396H36.4444V11.7063H68.3333C69.7 11.7063 70.6111 12.6174 70.6111 13.9841V39.0396C70.6111 40.4063 69.7 41.3174 68.3333 41.3174H38.7222V57.2619H26.65L18.2222 63.8674V57.2619H11.3889C10.0222 57.2619 9.1111 56.3508 9.1111 54.9841V29.9285C9.1111 28.5619 10.0222 27.6508 11.3889 27.6508H41V23.0952H11.3889C7.51665 23.0952 4.55554 26.0563 4.55554 29.9285V54.9841C4.55554 58.8563 7.51665 61.8174 11.3889 61.8174H13.6667V73.4341L28.0167 61.8174H43.2778V45.873H68.3333C72.2055 45.873 75.1667 42.9119 75.1667 39.0396V13.9841C75.1667 10.1119 72.2055 7.15076 68.3333 7.15076Z"
      fill="#811C62"
    />
    <path
      d="M14.1222 52.4784H19.5889L20.9555 48.8339H28.0166L29.3833 52.4784H34.85L27.1055 32.2061H21.6389L14.1222 52.4784ZM24.3722 37.9006L26.65 44.9617H22.0944L24.3722 37.9006Z"
      fill="#811C62"
    />
    <path
      d="M45.5555 39.0395C48.0611 39.0395 51.4778 38.3562 54.6667 36.7617C57.8555 38.3562 61.5 39.0395 63.7778 39.0395V34.4839C63.7778 34.4839 61.5 34.4839 58.9944 33.5728C61.7278 30.8395 63.7778 26.7395 63.7778 20.8173V18.5395H56.9444V13.9839H52.3889V18.5395H45.5555V23.0951H58.9944C58.5389 27.1951 56.7167 29.7006 54.6667 31.2951C53.3 30.1562 51.9333 28.5617 51.0222 26.5117H46.2389C47.15 29.4728 48.5167 31.7506 50.3389 33.5728C48.0611 34.4839 46.0111 34.4839 45.5555 34.4839V39.0395Z"
      fill="#811C62"
    />
  </svg>
)

export default LanguageSwitch
